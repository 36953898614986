
import { Config } from 'client-website-ts-library/services';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import { FormConstructorData } from 'client-website-ts-library/types/Forms';
import { Component, Vue } from 'vue-property-decorator';

import Form from '../components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class LeaseAppraisal extends Vue {
  private formData: FormConstructorData | null = null;

  mounted() {
    this.formData = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        {
          Type: ContextItemType.EnquiryTargetProfile,
          Id: '3376ff2a-8bee-41d4-afc2-096c6ae3a559',
        },
      ],
    };
  }
}
